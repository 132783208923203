import React, { useContext,useEffect,useRef,useState } from "react";
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { PageTitle } from "../../PageTitle";
import { UserContext } from "../../globalContext";
import { useNavigate } from "react-router-dom";
import { Footer } from "antd/lib/layout/layout";
import ludo from "../../images/ludo/ludoavt.svg"
import winimg from "../../images/ludo/winimg.webp"
import { Button } from "react-bootstrap";
import refere from "../../static/media/refere.webp"
const Refere = (props) => {

  const textRef = useRef(null);
  const textRef2 = useRef(null);

  const user = useContext(UserContext);
  let navigate = useNavigate();

  const [referLink ,setReferLink] = useState();
  const [dataToSend1 ,setdataToSend1] = useState();
  useEffect(() => {
    
    let mounted = true;
    if (mounted) {

   
      if (user.userId == null) {
       window.location.reload(true);
      }

      
    }
    pageLoad();
    return () => (mounted = false);
  }, []);
  const pageLoad = () => {
    const dataToSend = {  ReferCode: user.memberReferCode };
    setdataToSend1(dataToSend);
    const dataa =`http://ludokingjaipur.com/#/register/${encodeURIComponent(
      JSON.stringify(dataToSend),
    )}`
    console.log("dataa",dataa)
    setReferLink(dataa)
    console.log("user",`http://ludokingjaipur.com/#/register/${encodeURIComponent(
      JSON.stringify(dataToSend),
    )}`)
  }
  const handleCopyClick = () => {
    if (textRef.current) {
      textRef.current.select();
      document.execCommand('copy');
      alert('Text copied to clipboard!');
    }
  };
  const handleCopyClick2 = () => {
    if (textRef2.current) {
      textRef2.current.select();
      document.execCommand('copy');
      alert('Text copied to clipboard!');
    }
  };
  return (
   <div  className="realludokingsize"> 
  <div className="col-12 mx-auto g-0 iframe-sec p-3">
      <div>
        <div className="mb-3 card">
          <div className="bg-light text-dark card-header">Your Referral Earnings</div>
          <div className="card-body">
            <div className="d-flex align-items-center justify-content-between">
              <div className="d-flex flex-column border-end flex-grow-1 align-items-center justify-content-center">
                <span className="text-capitalize fw-bold" style={{ fontSize: '0.8rem' }}>referred players</span>
                <span>₹0</span>
              </div>
              <div className="d-flex flex-column align-items-center justify-content-center flex-grow-1">
                <span className="text-capitalize fw-bold" style={{ fontSize: '0.8rem' }}>Referral Earning</span>
                <span>₹0.00</span>
              </div>
            </div>
          </div>
        </div>
        <div className="mb-3 card">
          <div className="bg-light text-dark card-header">Referral Code</div>
          <div className="card-body">
            <div>
              <div>
                <div>
                  <div className="text-center">
                    <img src={refere} alt="refer" className=" mx-auto kmt" />
                  </div>
                  <div>
                    <div>
                      <div>
                        <div className="input-group">
                          <input type="text" className="form-control p-2 WW56" disable value={user.memberReferCode}  ref={textRef}/>
                          <input type="text" className="form-control p-2 referInput" disable value={`Play Ludo and earn Rs10000 daily.
Commission Charge - 5% Only
Referral - 2% On All Games
24x7 Live Chat Support
Instant Withdrawal Via UPI/Bank
Register Now, My refer code is ${user.memberReferCode}.
👇👇
https://ludokingjaipur.com/#/register/${encodeURIComponent(
                    JSON.stringify(dataToSend1),
                  )}`}  ref={textRef2}/>
                          <button className="btn btn-primary text-uppercase" onClick={handleCopyClick}>copy</button>
                        </div>
                      </div>
                    </div>
                    <p className="text-uppercase fw-bold fs-3 p-0 m-0 my-3 text-center">or</p>
                    {/* <div className="d-grid">
                      <a href={`whatsapp://send?text=Play Ludo and earn Rs10000 daily.%0ACommission Charge - 5% Only%0AReferral - 2% On All Games%0A24x7 Live Chat Support%0AInstant Withdrawal Via UPI/Bank%0ARegister Now, My refer code is ${user.memberReferCode}.%0A👇👇%0A${referLink}`}>
                        <button className="btn btn-success btn-md w-100">
                          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16" width="1em" height="1em" fill="currentColor" className="me-2">
                            <path d="M13.601 2.326A7.854 7.854 0 0 0 7.994 0C3.627 0 .068 3.558.064 7.926c0 1.399.366 2.76 1.057 3.965L0 16l4.204-1.102a7.933 7.933 0 0 0 3.79.965h.004c4.368 0 7.926-3.558 7.93-7.93A7.898 7.898 0 0 0 13.6 2.326zM7.994 14.521a6.573 6.573 0 0 1-3.356-.92l-.24-.144-2.494.654.666-2.433-.156-.251a6.56 6.56 0 0 1-1.007-3.505c0-3.626 2.957-6.584 6.591-6.584a6.56 6.56 0 0 1 4.66 1.931 6.557 6.557 0 0 1 1.928 4.66c-.004 3.639-2.961 6.592-6.592 6.592zm3.615-4.934c-.197-.099-1.17-.578-1.353-.646-.182-.065-.315-.099-.445.099-.133.197-.513.646-.627.775-.114.133-.232.148-.43.05-.197-.1-.836-.308-1.592-.985-.59-.525-.985-1.175-1.103-1.372-.114-.198-.011-.304.088-.403.087-.088.197-.232.296-.346.1-.114.133-.198.198-.33.065-.134.034-.248-.015-.347-.05-.099-.445-1.076-.612-1.47-.16-.389-.323-.335-.445-.34-.114-.007-.247-.007-.38-.007a.729.729 0 0 0-.529.247c-.182.198-.691.677-.691 1.654 0 .977.71 1.916.81 2.049.098.133 1.394 2.132 3.383 2.992.47.205.84.326 1.129.418.475.152.904.129 1.246.08.38-.058 1.171-.48 1.338-.943.164-.464.164-.86.114-.943-.049-.084-.182-.133-.38-.232z"></path>
                          </svg>
                          <span className="text-capitalize">share on whatsapp</span>
                        </button>
                      </a>
                    </div> */}
                    <div className="d-grid mt-2">
                      <button className="btn btn-success btn-md w-100" onClick={handleCopyClick2}>
                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16" width="1em" height="1em" fill="currentColor" className="me-2">
                          <path d="M4 1.5H3a2 2 0 0 0-2 2V14a2 2 0 0 0 2 2h10a2 2 0 0 0 2-2V3.5a2 2 0 0 0-2-2h-1v1h1a1 1 0 0 1 1 1V14a1 1 0 0 1-1 1H3a1 1 0 0 1-1-1V3.5a1 1 0 0 1 1-1h1v-1z"></path>
                          <path d="M9.5 1a.5.5 0 0 1 .5.5v1a.5.5 0 0 1-.5.5h-3a.5.5 0 0 1-.5-.5v-1a.5.5 0 0 1 .5-.5h3zm-3-1A1.5 1.5 0 0 0 5 1.5v1A1.5 1.5 0 0 0 6.5 4h3A1.5 1.5 0 0 0 11 2.5v-1A1.5 1.5 0 0 0 9.5 0h-3z"></path>
                        </svg>
                        <span className="text-capitalize">copy to clipboard</span>
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="mb-3 card">
          <div className="bg-light text-dark card-header">How It Works</div>
          <div className="card-body">
            <ul className="list-group">
              <li className="list-group-item">You can refer and <b>Earn 2%</b> of your referral winning, every time</li>
              <li className="list-group-item">Like if your player plays for <b>₹10000</b> and wins, You will get <b>₹200</b> as referral amount.</li>
            </ul>
          </div>
        </div>
      </div>
    </div>
 </div>
     );
};

export default Refere;