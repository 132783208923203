import React, { useContext, useEffect, useRef, useState } from 'react';
import { message } from 'antd';
import { useNavigate, useParams } from 'react-router-dom';
import Tesseract from 'tesseract.js';
import axiosInstance from '../../axiosInstance';
import Loader from './Loader';
import { UserContext } from '../../globalContext';
import vs from '../../static/media/vs.png';

function GameOne() {
  const user = useContext(UserContext);
  let navigate = useNavigate();

  const textRef = useRef(null);
  const [image, setImage] = useState(null);
  const [image1, setImage1] = useState(null);
  const [loose, setLost] = useState(false);
  const [loose1, setLost1] = useState(false);
  const [win, setWin] = useState(false);
  const [wallet, setWallet] = useState();
  const [putWallet, setPutWallet] = useState(true);
  const [playerMatch, setPlayerMatch] = useState(false);
  const [tablePlayer, setTablePlayer] = useState(false);
  const [progress, setProgress] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const [otp, setOtp] = useState('');
  const [password, setpassword] = useState('');
  const [mobile, setEmail] = useState('');
  const [minutes, setMinutes] = useState(0);
  const [seconds, setSeconds] = useState(0);
  const [winningAmt, setWinningAmt] = useState(false);
  const [Otp, setotp] = useState(1);
  const [Subb, setSubb] = useState(1);
  const [originalDate, setOriginalDate] = useState(new Date());
  const [verificationWords, setVerificationWords] = useState('congratulation');
  const [roomCode1, setRoomCode1] = useState('05948185');
  const [result, setResult] = useState('');
  const [result2, setResult2] = useState('');
  const [player11, setPlayer11] = useState('');
  const [player22, setPlayer22] = useState('');
  const [WaitLoader, setWaitLoader] = useState(false);
  const [WaitLoader1, setWaitLoader1] = useState(false);
  const [froud1, setFroud1] = useState(false);

  const { data } = useParams();
  const decodedData = JSON.parse(decodeURIComponent(data));
  const originalDate1 = new Date(decodedData.gameDate);
  const resendOTP = () => {
    setMinutes(1);
    setSeconds(30);
  };
  useEffect(() => {
    let mounted = true;
    if (mounted) {
    }
    pageLoad();
    return () => (mounted = false);
  }, []);
  const pageLoad = () => {
    palayerFind();
    gameView();
    getWallet();
    gameSView();
  };
  const gameSView = () => {
    if (loose === false) {
      axiosInstance.get(`/game/${decodedData._id}`).then(response => {
        if (response.data.data.winner === 'inreview') {
          alert("यह टेबल खिलाड़ी द्वारा रद्द कर दी गई है, हमारी टीम जल्द ही आपसे संपर्क करेगी, आप सपोर्ट पर जाकर भी सहायता प्राप्त कर सकते हैं।")
          navigate(`/Game1`, { replace: true });

          palayerFind();
        } 
        if (response.data.data.status === 'success') {
          setPlayer11(response.data.data.player1._id);
          setPlayer22(response.data.data.player2._id);
          setWinningAmt(response.data.data.winningAmount);
          setLost(true);
          setPlayerMatch(true);
          setTablePlayer(response.data.data);
          myStatus(response.data.data);
        } else {
          gameSView();
        }
      });
    }
  };

  const myStatus = obj => {
    if (obj.winner === 'player1') {
      if (obj.player1._id === user.userId) {
        navigate(`/Game1`, { replace: true });
      }
    } else {
      if (obj.winner === 'player2') {
        if (obj.player2._id === user.userId) {
          navigate(`/Game1`, { replace: true });
        }
      }
    }
  };
  const getWallet = () => {
    axiosInstance.get(`/wallet/${user.userId}`).then(res => {
      setWallet(res.data.data);
    });
  };
  const gameView = () => {
    if (decodedData.status === 'joined') {
      setPlayer11(decodedData.player1._id);
      setPlayer22(decodedData.player2._id);
      setWinningAmt(decodedData.winningAmount);
      setTablePlayer(decodedData);
      setPlayerMatch(true);
      putPayment();
      countTime2(decodedData)

    }
  };
  var putP = 1;
  const putPayment = () => {
    if (putP === 1) {
      putP = putP + 1;
      if (putWallet === true) {
        if (decodedData.status === 'joined') {
          axiosInstance.get(`/game/${decodedData._id}`).then(res => {
            if (res.data.data.debitAmount === false) {
              if (res.data.data.player2._id === user.userId) {
                axiosInstance
                  .put(`/game/${decodedData._id}`, { debitAmount: true })
                  .then(response => {

                    setPutWallet(false);
                    putWallet1(res.data.data);
                    putWallet2(res.data.data);
                    putProfit(res.data.data);
                  });
              }
            }
          });
        }
      }
    }
  };

  const putWallet1 = obj => {
    axiosInstance.get(`/wallet/${obj.player1._id}`).then(res => {
      if (res.data.data.depositeAmount >= obj.gameAmount) {
        const data1 = {
          depositeAmount: res.data.data.depositeAmount - obj.gameAmount,
        };
        axiosInstance.put(`wallet/${obj.player1._id}`, data1).then(res => {
          if (res.data && res.data.responseCode === 1) {
            //message.success("Amount debited successfully");
          } else message.error('Something wrong. Please try again...!');
        });
      } else {
        const sAmount = obj.gameAmount - res.data.data.depositeAmount;

        const data2 = {
          winningAmount: res.data.data.winningAmount - sAmount,
          depositeAmount: 0,
        };
        axiosInstance.put(`wallet/${obj.player1._id}`, data2).then(res => {
          if (res.data && res.data.responseCode === 1) {
            //message.success("Amount debited successfully");
          } else message.error('Something wrong. Please try again...!');
        });
      }
    });
  };
  const putWallet2 = obj => {
    axiosInstance.get(`/wallet/${obj.player2._id}`).then(res => {
      if (res.data.data.depositeAmount >= obj.gameAmount) {
        const data1 = {
          depositeAmount: res.data.data.depositeAmount - obj.gameAmount,
        };
        axiosInstance.put(`wallet/${obj.player2._id}`, data1).then(res => {
          if (res.data && res.data.responseCode === 1) {
            message.success('Amount debited successfully');
          } else message.error('Something wrong. Please try again...!');
        });
      } else {
        const sAmount = obj.gameAmount - res.data.data.depositeAmount;

        const data2 = {
          winningAmount: res.data.data.winningAmount - sAmount,
          depositeAmount: 0,
        };
        axiosInstance.put(`wallet/${obj.player2._id}`, data2).then(res => {
          if (res.data && res.data.responseCode === 1) {
            message.success('Amount debited successfully');
          } else message.error('Something wrong. Please try again...!');
        });
      }
    });
  };
  const putProfit = obj => {
    const data1 = {
      amount: obj.gameAmount * 0.1,
      date: new Date(),
    };
    axiosInstance.post(`profit`, data1).then(res => {
      if (res.data && res.data.responseCode === 1) {
        // message.success("Amount debited successfully");
      } else message.error('Something wrong. Please try again...!');
    });
  };
  const putGameDepositeStatus = () => {
    axiosInstance
      .put(`/game/${decodedData._id}`, { debitAmount: true })
      .then(response => {
      });
  };
  const palayerFind = () => {
    if (decodedData.status !== 'joined') {
      axiosInstance.get(`/game/${decodedData._id}`).then(res => {
    console.log("hiiii",res.data.data.status)

        if (res.data.data.status === 'created') {
    countTime();

          palayerFind();
        } else {
          if (res.data.data.status === 'cancelled') {
            navigate(`/Game1`, { replace: true });
            //message.error('Player not found...!');
          } else {
            if (res.data.data.status === 'joined') {
              countTime2(res.data.data)
              setTablePlayer(res.data.data);
              setPlayer11(res.data.data.player1._id);
              setPlayer22(res.data.data.player2._id);
              setPlayerMatch(true);
            } else {
              if (res.data.data.status === 'success') {
                setPlayer11(res.data.data.player1._id);
                setPlayer22(res.data.data.player2._id);
                setWinningAmt(res.data.data.winningAmount);
                setLost(true);
                setPlayerMatch(true);
                setTablePlayer(res.data.data);
              }
              else {
                if (res.data.data.status === 'inreview') {
                  navigate(`/Game1`, { replace: true });
                  message.error('froud found in this game...!');
                }
              }
            }
           
          }
        }
      });
    }
    // else{
    //   axiosInstance.get(`/game/${decodedData._id}`).then(res => {   
    //         if (res.data.data.winner === 'inreview') {
    //           navigate(`/Game1`, { replace: true });
    
    //           palayerFind();
    //         } 
    //       });
    // }
  };
  const countTime = () => {
    const decodedData = JSON.parse(decodeURIComponent(data));
    const originalDate1 = new Date(decodedData.gameDate);

    const updatedDate = new Date(originalDate1.getTime() + 5 * 60000); // 5 minutes in milliseconds
    if (updatedDate > new Date()) {
      const diffTime = Math.abs(new Date() - updatedDate);
      const diffDays = Math.ceil(diffTime / 1000);
      setMinutes(Math.floor(diffDays / 60));
      setSeconds(diffDays % 60);
    } else {
      palayerFind();
    }
  };
  const countTime2 = (e) => {
    setFroud1(false)

    const decodedData = JSON.parse(decodeURIComponent(data));
    const originalDate1 = new Date(e.gameJoinedDate);

    const updatedDate = new Date(originalDate1.getTime() + 1 * 60000); // 5 minutes in milliseconds
    if (updatedDate > new Date()) {
      const diffTime = Math.abs(new Date() - updatedDate);
      const diffDays = Math.ceil(diffTime / 1000);
      setMinutes(Math.floor(diffDays / 60));
      setSeconds(diffDays % 60);

    } else {
      setFroud1(true)
      palayerFind();
    }
  };

  const handleCopyClick = () => {
    resendOTP();
    if (textRef.current) {
      textRef.current.select();
      document.execCommand('copy');
      alert('Text copied to clipboard!');
    }
  };
  const handleChange = event => {
    setIsLoading(true);
    setImage(URL.createObjectURL(event.target.files[0]));
    setIsLoading(false);
  };
  const handleChange1 = event => {
    setIsLoading(true);
    setImage(URL.createObjectURL(event.target.files[0]));
    setImage1(event.target.files[0]);
    setIsLoading(false);
  };
  const deleteImage = () => {
    setIsLoading(true);
    setImage(null);
    setIsLoading(false);
  };
  useEffect(() => {
    const interval = setInterval(() => {
      if (seconds > 0) {
        setSeconds(seconds - 1);
        palayerFind();

      }
      if (seconds === 0) {
        if (minutes === 0) {
          clearInterval(interval);
          pageLoad();
          setFroud1(true)
          palayerFind();
        } else {
          setSeconds(59);
          setMinutes(minutes - 1);
        }
      }
    }, 1000);
    return () => {
      clearInterval(interval);
    };
  }, [seconds]);

  const winner = () => {
    if (user.userId === player11) {
      const data = {
        status: 'success',
        player1Status: 'winner',
        winner: 'player1',
        froudStatus: false,
      };
      axiosInstance.put(`/game/${decodedData._id}`, data).then(res => {
        axiosInstance.get(`/wallet/${user.userId}`).then(response => {
          const data1 = {
            winningAmount:
              response.data.data.winningAmount + decodedData.winningAmount,
            totalWinningAmount:
              response.data.data.totalWinningAmount + decodedData.winningAmount,
          };
          axiosInstance.put(`/wallet/${user.userId}`, data1).then(res => {
            message.success('your winning amount added your wallet');
            navigate(`/Game1`, { replace: true });
          });
        });
      });
    } else {
      if (user.userId === player22) {
        const data = {
          status: 'success',
          player2Status: 'winner',
          winner: 'player2',
          froudStatus: false,
        };
        axiosInstance.put(`/game/${decodedData._id}`, data).then(res => {
          axiosInstance.get(`/wallet/${user.userId}`).then(response => {
            const data1 = {
              winningAmount:
                response.data.data.winningAmount + decodedData.winningAmount,
              totalWinningAmount:
                response.data.data.totalWinningAmount +
                decodedData.winningAmount,
            };
            axiosInstance.put(`/wallet/${user.userId}`, data1).then(res => {
              message.success('your winning amount added your wallet');
              navigate(`/Game1`, { replace: true });
            });
          });
        });
      }
    }
  };

  const lost = () => {
    if (loose === false) {
      setWaitLoader1(true)
      if (user.userId === player11) {
        const data = {
          status: 'success',
          player1Status: 'looser',
          player2Status: 'winner',
          winner: 'player2',
          froudStatus: false,
        };
        axiosInstance.put(`/game/${decodedData._id}`, data).then(res => {
          axiosInstance.get(`/wallet/${player22}`).then(response1 => {
            const data1 = {
              winningAmount:
                response1.data.data.winningAmount + decodedData.winningAmount,
              totalWinningAmount:
                response1.data.data.totalWinningAmount +
                decodedData.winningAmount,
            };
            axiosInstance.put(`/wallet/${player22}`, data1).then(res => {
              message.success('your lost this game');
              setLost(true);
              setWaitLoader1(false);

              navigate(`/Game1`, { replace: true });
            });
          });
        });
      } else {
        if (user.userId === player22) {

          const data = {
            status: 'success',
            player2Status: 'looser',
            player1Status: 'winner',
            winner: 'player1',
            froudStatus: false,
          };
          axiosInstance.put(`/game/${decodedData._id}`, data).then(res => {
            axiosInstance.get(`/wallet/${player11}`).then(response2 => {
              const data1 = {
                winningAmount:
                  response2.data.data.winningAmount + decodedData.winningAmount,
                totalWinningAmount:
                  response2.data.data.totalWinningAmount +
                  decodedData.winningAmount,
              };
              axiosInstance.put(`/wallet/${player11}`, data1).then(res => {
                message.success('your lost this game');
                setLost(true);
                setWaitLoader1(false);

                navigate(`/Game1`, { replace: true });
              });
            });
          });
        }
      }
    } else {
      navigate(`/Game1`, { replace: true });
    }
  };

  const handleVerification = () => {
    setWaitLoader(true);
    Tesseract.recognize(
      image,
      'eng', // language code (English in this case)
      { logger: info => console.log(info) },
    ).then(({ data: { text } }) => {
      const wordsToVerify = verificationWords.toLowerCase().split(' ');
      const matchedWords = wordsToVerify.filter(word =>
        text.toLowerCase().includes(word),
      );
      if (verificationWords === matchedWords.join(', ')) {
        handleVerification2();
      } else {
        setLost1(false);
        setIsLoading(true);
        setImage(null);
        setIsLoading(false);
        alert('Your screenshot is not currect. Please try again...!');
        setWaitLoader(false);
      }
    });
  };
  const handleVerification2 = () => {
    Tesseract.recognize(
      image,
      'eng', // language code (English in this case)
      { logger: info => console.log(info) },
    ).then(({ data: { text } }) => {
      const wordsToVerify = decodedData.roomCode.toLowerCase().split(' ');
      const matchedWords = wordsToVerify.filter(word =>
        text.toLowerCase().includes(word),
      );
      if (decodedData.roomCode === matchedWords.join(', ')) {
        winner();
      } else {
        setIsLoading(true);
        setImage(null);
        setIsLoading(false);
        setLost1(false);
        alert('Your screenshot is not currect. Please try again...!');
        setWaitLoader(false);
      }
    });
  };
  const handleVerification3 = () => {
    setWaitLoader(true);

    Tesseract.recognize(
      image,
      'eng', // language code (English in this case)
      { logger: info => console.log(info) },
    ).then(({ data: { text } }) => {
      const wordsToVerify = verificationWords.toLowerCase().split(' ');
      const matchedWords = wordsToVerify.filter(word =>
        text.toLowerCase().includes(word),
      );
      if (verificationWords === matchedWords.join(', ')) {
        handleVerification4();
      } else {
        setWin(false);
        setIsLoading(true);
        setImage(null);
        setIsLoading(false);
        alert('Your screenshot is not currect. Please try again...!');
        setWaitLoader(false);
      }
    });
  };
  const handleVerification4 = () => {
    Tesseract.recognize(
      image,
      'eng', // language code (English in this case)
      { logger: info => console.log(info) },
    ).then(({ data: { text } }) => {
      const wordsToVerify = decodedData.roomCode.toLowerCase().split(' ');
      const matchedWords = wordsToVerify.filter(word =>
        text.toLowerCase().includes(word),
      );
      if (decodedData.roomCode === matchedWords.join(', ')) {
        froud();
      } else {
        setWin(false);
        setIsLoading(true);
        setImage(null);
        setIsLoading(false);
        alert('Your screenshot is not currect. Please try again...!');
        setWaitLoader(false);
      }
    });
  };
  const win1 = () => {
    setWin(true);
  };
  const win2 = () => {
    setLost1(true);
  };
  const froud = () => {
    const formData = new FormData();
    formData.append('file', image1);
    axiosInstance.post(`/upload`, formData).then(res => {
      if (res.data && res.data.responseCode === -1) {
      } else if (res.data && res.data.responseCode === 1) {
        const data = {
          gameFDate: new Date(),
          player1: player11,
          player2: player22,
          game: decodedData._id,
          status: 'unsolved',
          reporter: user.userId,
          gameAmount: decodedData.gameAmount,
          winningAmount: decodedData.winningAmount,
          roomCode: decodedData.roomCode,
          image: res.data.data,
        };
        axiosInstance.post(`/game1F`, data).then(res => {
          if (res.data && res.data.responseCode === -1) {
            console.log('Record Already Exists');
          } else if (res.data && res.data.responseCode === 1) {
            if (user.userId !== player11) {
              axiosInstance.get(`/wallet/${player11}`).then(res => {
                const data1 = {
                  winningAmount:
                    res.data.data.winningAmount - decodedData.winningAmount,
                  totalWinningAmount:
                    res.data.data.totalWinningAmount -
                    decodedData.winningAmount,
                  winningFreezAmount: decodedData.winningAmount,
                };
                axiosInstance.put(`/wallet/${player11}`, data1).then(res => {
                  const data = {
                    status: 'success',
                    player1Status: 'inreview',
                    player2Status: 'inreview',
                    froudStatus: true,
                    winner:"inreview"

                  };
                  axiosInstance
                    .put(`/game/${decodedData._id}`, data)
                    .then(res => {
                      message.success('Your complaint has been received.');
                      navigate(`/Game1`, { replace: true });
                    });
                });
              });
            } else {
              if (user.userId === player11) {
                axiosInstance.get(`/wallet/${player22}`).then(res => {
                  const data1 = {
                    winningAmount:
                      res.data.data.winningAmount - decodedData.winningAmount,
                    totalWinningAmount:
                      res.data.data.totalWinningAmount -
                      decodedData.winningAmount,
                    winningFreezAmount: decodedData.winningAmount,
                  };
                  axiosInstance.put(`/wallet/${player22}`, data1).then(res => {
                    const data = {
                      status: 'success',
                      player1Status: 'inreview',
                      player2Status: 'inreview',
                      froudStatus: true,
                    winner:"inreview"

                    };
                    axiosInstance
                      .put(`/game/${decodedData._id}`, data)
                      .then(res => {
                        message.success('Your complaint has been received.');
                        navigate(`/Game1`, { replace: true });
                      });
                  });
                });
              }
            }
          } else console.log('Something wrong. Please try again...!');
        });
      } else console.log('Something wrong. Please try again...!');
    });
  };

  const onCancleF = () => {
    setWaitLoader1(true)

        const data = {
          gameFDate: new Date(),
          player1: player11,
          player2: player22,
          game: decodedData._id,
          status: 'unsolved1',
          reporter: user.userId,
          gameAmount: decodedData.gameAmount,
          winningAmount: decodedData.winningAmount,
          roomCode: decodedData.roomCode,
        };
        axiosInstance.post(`/game1F`, data).then(res => {
          if (res.data && res.data.responseCode === -1) {
            console.log('Record Already Exists');
          } else if (res.data && res.data.responseCode === 1) {
                  const data = {
                    status: 'success',
                    player1Status: 'inreview',  
                    player2Status: 'inreview',
                    froudStatus: true,
                    winner:"inreview"
                  };
                  axiosInstance
                    .put(`/game/${decodedData._id}`, data)
                    .then(res => {
                      message.success('Your complaint has been received.');
                      setWaitLoader1(false)
                      navigate(`/Game1`, { replace: true });
                    });
               
            } 
          })
         
      
  };

  const onCancleGame = () => {
    setWaitLoader1(true)
  axiosInstance
  .put(`/game/${decodedData._id}`,{status:"cancelled"})
  .then(response => {
    console.log("response",response)
    if (response.data.responseCode === 1) {
      message.success("Table Canceled successfully")
     navigate('/game1')
    }
  });
      console.log("I am working")
  };
  return (
    <div className="realludokingsize">
      {!playerMatch && (
        <div>
          <div className="realludokingsize">
            <div className="card-02">
              <div class="d-flex align-items-center justify-content-between">
                <div class="d-flex rj232323 align-items-center justify-content-center">
                  <button className="rajababau raj78">
                    <span class="text-capitalize fw-bold text-center">
                      Table : {decodedData.gameAmount}
                    </span>
                  </button>
                </div>

                <div class="d-flex rj232323 align-items-center justify-content-center">
                  <button className="rajababau">
                    <span class="text-capitalize fw-bold text-center">
                      Wining : {decodedData.winningAmount}
                    </span>
                  </button>
                </div>
              </div>
            </div>
            <div className="card-02">
              <div class=" card">
                <div className='shadow02'>

                  <div class="bg-light text-dark"></div>
                  <div class="card-body">
                    <div class="d-flex align-items-center justify-content-between">
                      <div class="d-flex rj232323 align-items-center justify-content-center">
                        <span class="text-capitalize fw-bold text-center">
                          {user.userName}
                        </span>
                      </div>
                      <div class="d-flex rj232323 align-items-center justify-content-center">
                        <span class="text-capitalize fw-bold text-center">
                          <img className="vs" src={vs} alt="" />
                        </span>
                      </div>
                      <div class="d-flex rj232323 align-items-center justify-content-center">
                        <span class="text-capitalize fw-bold text-center d-flex justify-content-center align-items-center">
                          <div class="spinner-border text-primary" role="status">
                            <span
                              class="sr-only "
                              loaded={false}
                              shadow={false}></span>
                          </div>
                          <span className="ms-2" loaded={false} shadow={false}>
                            Finding...
                          </span>
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="card-body">
            <h5 className="mr">please wait a few minutes</h5>
            <div className="card">
              <div className=" m-0 w-100 countdoen">
                {seconds > 0 || minutes > 0 ? (
                  <p>
                    {minutes < 10 ? `0${minutes}` : minutes}:
                    {seconds < 10 ? `0${seconds}` : seconds}
                  </p>
                ) : (
                  <p>Can't find?</p>
                )}
              </div>
              {!WaitLoader1 && (
            <div className="lost card-body">
              <p className="mycr">if you Cancel game click here..?</p>
              <button
                class="btn btn-danger btn-md w-100 "
                onClick={() => onCancleGame()}>
                <span class="text-capitalize">Cancle</span>
              </button>
            </div>
          )}
          {WaitLoader1 && (
            <div className="lost card-body">

              <button class="btn text-capitalize d-flex align-items-center justify-content-center  btn-danger btn-md w-100 mb-3 mt-3">
                <span className="text-capitalize"> Please wait...</span>
                <div class="spinner-border text-light ms-3" role="status">
                  <span class="visually-hidden">Loading...</span>
                </div>
              </button>
            </div>
          )}
            </div>
          </div>
        </div>
      )}
      {playerMatch && (
        <div className="realludokingsize">
          <div className="card-02">
            <div class="d-flex align-items-center justify-content-between">
              <div class="d-flex rj232323 align-items-center justify-content-center">
                <button className="rajababau raj78">
                  <span class="text-capitalize fw-bold text-center">
                    Table : {decodedData.gameAmount}
                  </span>
                </button>
              </div>

              <div class="d-flex rj232323 align-items-center justify-content-center">
                <button className="rajababau">
                  <span class="text-capitalize fw-bold text-center">
                    Wining : {decodedData.winningAmount}
                  </span>
                </button>
              </div>
            </div>
          </div>

          <div className="card-02">
            <div class="mb-3 card">
              <div className='shadow02'>

                <div class="bg-light text-dark"></div>
                <div class="card-body">
                  <div class="d-flex align-items-center justify-content-between">
                    <div class="d-flex rj232323 align-items-center justify-content-center">
                      <span class="text-capitalize fw-bold text-center">
                        {tablePlayer.player1.name}
                      </span>
                    </div>
                    <div class="d-flex rj232323 align-items-center justify-content-center">
                      <span class="text-capitalize fw-bold text-center">
                        <img className="vs" src={vs} alt="" />
                      </span>
                    </div>
                    <div class="d-flex rj232323 align-items-center justify-content-center">
                      <span class="text-capitalize fw-bold text-center">
                        {tablePlayer.player2.name}
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="card-body">
            <p class="text-center menn">Room Code</p>
            <div>
              <div>
                <div class="input-group">
                  <input
                    className="form-control mye"
                    ref={textRef}
                    value={decodedData.roomCode}
                  />
                </div>
              </div>
            </div>

            <div class="d-grid mt-2">
              <button class="btn  btn-success btn-md w-100">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 16 16"
                  width="1em"
                  height="1em"
                  fill="currentColor"
                  class="me-2">
                  <path d="M4 1.5H3a2 2 0 0 0-2 2V14a2 2 0 0 0 2 2h10a2 2 0 0 0 2-2V3.5a2 2 0 0 0-2-2h-1v1h1a1 1 0 0 1 1 1V14a1 1 0 0 1-1 1H3a1 1 0 0 1-1-1V3.5a1 1 0 0 1 1-1h1v-1z"></path>
                  <path d="M9.5 1a.5.5 0 0 1 .5.5v1a.5.5 0 0 1-.5.5h-3a.5.5 0 0 1-.5-.5v-1a.5.5 0 0 1 .5-.5h3zm-3-1A1.5 1.5 0 0 0 5 1.5v1A1.5 1.5 0 0 0 6.5 4h3A1.5 1.5 0 0 0 11 2.5v-1A1.5 1.5 0 0 0 9.5 0h-3z"></path>
                </svg>
                <span class="text-capitalize" onClick={handleCopyClick}>
                  copy & Play
                </span>
              </button>
            </div>
          </div>
          {!loose1 && !loose && (
            <div class="card-02">
              <div>
                <div>
                  <div role="alert" class="fade alert mb-0 alert-primary show">
                    <p className="text-danger">
                      {' '}
                      Please copy the room code or enter the private room code
                      of your Ludo King app and play the game.
                    </p>
                    <p className="text-danger">
                      {' '}
                      After completion of the game, submit the screenshot of
                      winning.
                    </p>
                    <p className="text-danger">
                      {' '}
                      Without winning screenshot, your winning amount will not
                      be added to your wallet and you will be deemed to have
                      lost the game.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          )}
          {loose && (
            <div class="card-02">
              <div>
                <div>
                  <div role="alert" class="fade alert mb-0 alert-primary show">
                    <p style={{ color: 'red' }}>
                      {' '}
                      आपके गेम पार्टनर ने विनिंग स्क्रीन शॉट अपलोड कर दिया है
                      अगर आप हार गए तो Lost पर क्लिक करें |
                    </p>
                    <p style={{ color: 'red' }}>
                      {' '}
                      अगर आप विजेता है तो Win पर क्लिक करें |
                    </p>
                  </div>
                </div>
              </div>
            </div>
          )}
           {!loose1 && !loose  && !WaitLoader1 && (
            <div className="lost card-body">
              <p className="mycr">if you win click here..?</p>
              <button
                class="btn btn-success btn-md w-100 "
                onClick={() => win2()}>
                <span class="text-capitalize">I Win</span>
              </button>
            </div>
          )}
          {!loose1 && !win  && !WaitLoader1 && (
            <div>
            <div className="lost card-body">
              <p className="mycr">if you don't join table click here..?</p>
              <button onClick={() => onCancleF()}
                class="btn btn-danger btn-md w-100 "
              >
                <span class="text-capitalize">Cancel</span>
              </button>
            </div>
          
            </div>
            
          )}
          {!loose1 && !win   && !WaitLoader1 && (
            <div className="lost card-body">
              <p className="mycr">if you lost click here..?</p>
              <button
                class="btn btn-danger btn-md w-100 "
                onClick={() => lost()}>
                <span class="text-capitalize">I lost</span>
              </button>
            </div>
          )}

          {WaitLoader1 && (
            <div className="lost card-body">

              <button class="btn text-capitalize d-flex align-items-center justify-content-center  btn-danger btn-md w-100 mb-3 mt-3">
                <span className="text-capitalize"> Please wait...</span>
                <div class="spinner-border text-light ms-3" role="status">
                  <span class="visually-hidden">Loading...</span>
                </div>
              </button>
            </div>
          )}
         
          {loose && !win && (
            <div className="lost card-body">
              <p className="mycr">if you win click here..?</p>
              <button
                class="btn  btn-success btn-md w-100"
                onClick={() => win1()}>
                <span class="text-capitalize">I Win</span>
              </button>
            </div>
          )}

          {loose1 && (
            <div className="card-body">
              {isLoading ? (
                <Loader />
              ) : (
                <>
                  {!image ? (
                    <>
                      <div class="wrapper">
                        <div class="container">
                          <div class="upload-container">
                            <div class="border-container">
                              <div class="icons fa-4x">
                                <i
                                  class="fas fa-file-image"
                                  data-fa-transform="shrink-3 down-2 left-6 rotate--45"></i>
                                <i
                                  class="fas fa-file-alt"
                                  data-fa-transform="shrink-2 up-4"></i>
                                <i
                                  class="fas fa-file-pdf"
                                  data-fa-transform="shrink-3 down-2 right-6 rotate-45"></i>
                              </div>
                              <p className="text-center">
                                Drag and drop files here, or
                                <div className="text-center w-100">
                                  <input
                                    type="file"
                                    name="uploadimage"
                                    onChange={handleChange}
                                    className="upload text-center w-100"
                                  />
                                </div>
                                your computer.
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </>
                  ) : (
                    <>
                      <div className="dispaly-image card-body">
                        <div className="card-body">
                          <img
                            src={image}
                            style={{ width: '100%' }}
                            alt="uploaded"
                            className="uploaded-image"
                          />
                          <div class="d-grid mt-2"></div>
                        </div>
                        {!WaitLoader && (
                          <button
                            class="btn btn-danger btn-md w-100 mb-3"
                            onClick={deleteImage}>
                            <span class="text-capitalize">Delete</span>
                          </button>
                        )}
                        {!WaitLoader && (
                          <button
                            class="btn  btn-success btn-md w-100 mb-3"
                            onClick={() => handleVerification()}>
                            <span class="text-capitalize">Submit</span>
                          </button>
                        )}
                        {WaitLoader && (
                          <button class="btn text-capitalize d-flex align-items-center justify-content-center  btn-success btn-md w-100 mb-3">
                            <span className="text-capitalize">
                              {' '}
                              Please wait...
                            </span>
                            <div
                              class="spinner-border text-light ms-3"
                              role="status">
                              <span class="visually-hidden">Loading...</span>
                            </div>
                          </button>
                        )}
                        {result && <p>{result}</p>}
                        {result2 && <p>{result2}</p>}
                      </div>
                    </>
                  )}
                </>
              )}
            </div>
          )}
          {win && (
            <div className="card-body">
              {isLoading ? (
                <Loader />
              ) : (
                <>
                  {!image ? (
                    <>
                      <div class="wrapper">
                        <div class="container">
                          <div class="upload-container">
                            <div class="border-container">
                              <div class="icons fa-4x">
                                <i
                                  class="fas fa-file-image"
                                  data-fa-transform="shrink-3 down-2 left-6 rotate--45"></i>
                                <i
                                  class="fas fa-file-alt"
                                  data-fa-transform="shrink-2 up-4"></i>
                                <i
                                  class="fas fa-file-pdf"
                                  data-fa-transform="shrink-3 down-2 right-6 rotate-45"></i>
                              </div>
                              <p className="text-center">
                                Drag and drop files here, or
                                <div className="text-center w-100">
                                  <input
                                    type="file"
                                    name="uploadimage"
                                    onChange={handleChange1}
                                    className="upload text-center w-100"
                                  />
                                </div>
                                your computer.
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </>
                  ) : (
                    <>
                      <div className="dispaly-image card-body">
                        <div className="card-body">
                          <img
                            src={image}
                            style={{ width: '100%' }}
                            alt="uploaded"
                            className="uploaded-image"
                          />
                          <div class="d-grid mt-2"></div>
                        </div>
                        {!WaitLoader && (
                          <button
                            class="btn btn-danger btn-md w-100 mb-3"
                            onClick={deleteImage}>
                            <span class="text-capitalize">Delete</span>
                          </button>
                        )}
                        {!WaitLoader && (
                          <button
                            class="btn  btn-success btn-md w-100 mb-3"
                            onClick={() => handleVerification3()}>
                            <span class="text-capitalize">Submit</span>
                          </button>
                        )}
                        {WaitLoader && (
                          <button class="btn text-capitalize d-flex align-items-center justify-content-center  btn-success btn-md w-100 mb-3">
                            <span className="text-capitalize">
                              {' '}
                              Please wait...
                            </span>
                            <div
                              class="spinner-border text-light ms-3"
                              role="status">
                              <span class="visually-hidden">Loading...</span>
                            </div>
                          </button>
                        )}
                        {result && <p>{result}</p>}
                        {result2 && <p>{result2}</p>}
                      </div>
                    </>
                  )}
                </>
              )}
            </div>
          )}
        </div>
      )}
    </div>
  );
}

export default GameOne;
