import React, { useContext, useEffect, useState,useRef } from "react";
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { UserContext } from "../../globalContext";
import { useNavigate } from "react-router-dom";
import { Footer } from "antd/lib/layout/layout";
import { Button } from "react-bootstrap";
import { message } from "antd";
import axiosInstance from "../../axiosInstance";
const Recharge = (props) => {
  const textRef = useRef(null);
const [amount , setAmount] = useState()
const [upi , setUPI] = useState()
const [upiId , setUPIId] = useState()
const [utr , setUtr] = useState()
const [utrShow , setUtrShow] = useState(true)
  const user = useContext(UserContext);
  let navigate = useNavigate();
  useEffect(() => {

    let mounted = true;
    if (mounted) {


      if (user.userId == null) {
        window.location.reload(true);
      }


    }
    pageLoad();
    return () => (mounted = false);
  }, []);
  const pageLoad = () => {
  getUpi()
  }

  const getUpi = () => {
    axiosInstance.post("/upi/active").then((response) => {
      setUPIId(response.data.data[0]._id);
      setUPI(response.data.data[0].name);
    });
  };
  const handleCopyClick = () => {
    if (textRef.current) {
      textRef.current.select();
      document.execCommand('copy');
      alert('Text copied to clipboard!');
    }
  };

  const onPay = () => {
axiosInstance.get('/orderId').then((res)=>{
  const userId = res.data.data[0].orderId +1
  const data ={
    orderId:res.data.data[0].orderId +1
  } 
  axiosInstance.put(`/orderId/${res.data.data[0]._id}`,data).then((res)=>{
    onRecharge(userId)
  })


})
   };
   const onRecharge = (e) => {
    const data = {
      userId:user.memberId,
      member:user.userId,
      amount:amount,
      status:"requested",
      orderId:e,
      transactionDate:new Date()
    };
    console.log(data)
     if(data.userId!==undefined  && data.member!==undefined&& data.amount!==undefined&& data.status!==undefined && data.orderId!==undefined&& data.orderId!==''&& data.status!==''&& data.amount!==''&& data.member!==''&& data.userId!==''){
      axiosInstance.post("/payment", data).then((res) => {
        if (res.data && res.data.responseCode === -1) {
          message.error("Record Already Exists");
        } else if (res.data && res.data.responseCode === 1) {
          message.success("Record saved successfully");
          onPayCheck(res.data.data)
        } else message.error("Something wrong. Please try again...!");
      });
   }
   else{
    message.error("Please fill out all required fields. And submit again...!");
   }
   
  };
  const onPayCheck = (e) => {
    console.log("onpayCheck",e)
     const data = {
      customer_mobile: user.mobile,
      user_token: "eb0c44f223f310f7a6bc94432b44d9e9",
      amount: e.amount,
      order_id: e.orderId,
      redirect_url: "https://ludokingjaipur.com/#/wallet",
      remark1: "testremark",
      remark2: "testremark2"
     }
    axiosInstance.post('/roomeCode/payment',data).then((res)=>{
        console.log("payCheckurl",res.data.result.payment_url)
        window.location.href=`${res.data.result.payment_url}`
    })
       };
  return (
<div className="realludokingsize">
  <div class="offcanvas offcanvas-bottom" tabindex="-1" id="offcanvasBottom" aria-labelledby="offcanvasBottomLabel">
    <div class="offcanvas-header">
  <div class="offcanvas-title h5"id="offcanvasBottomLabel">How To Play Games &amp; Earn?</div>
      <button type="button" class="btn-close text-reset" data-bs-dismiss="offcanvas" aria-label="Close"></button>
    </div>
    <div class="offcanvas-body small">
   Your Video
    </div>
  </div>
      <div className="p-3">
        <div className="mb-3 d-flex align-items-center justify-content-between">
          <div className="d-flex align-items-center justify-content-start">
            <a href="#/Wallet">
              <button className="btn btn-primary border" onClick={()=>setUtrShow(true)}>
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16" width="1em" height="1em" fill="currentColor" className="me-2">
                  <path fillRule="evenodd" d="M12 8a.5.5 0 0 1-.5.5H5.707l2.147 2.146a.5.5 0 0 1-.708.708l-3-3a.5.5 0 0 1 0-.708l3-3a.5.5 0 1 1 .708.708L5.707 7.5H11.5a.5.5 0 0 1 .5.5z"></path>
                </svg>
                <span className="text-capitalize">Back</span>
              </button>
            </a>
          </div>
          <button type="button" className="d-flex align-items-center btn btn-outline-primary btn-md" data-bs-toggle="offcanvas" data-bs-target="#offcanvasBottom" aria-controls="offcanvasBottom">
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16" width="1em" height="1em" fill="currentColor" className="me-1">
              <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z"></path>
              <path d="m8.93 6.588-2.29.287-.082.38.45.083c.294.07.352.176.288.469l-.738 3.468c-.194.897.105 1.319.808 1.319.545 0 1.178-.252 1.465-.598l.088-.416c-.2.176-.492.246-.686.246-.275 0-.375-.193-.304-.533L8.93 6.588zM9 4.5a1 1 0 1 1-2 0 1 1 0 0 1 2 0z"></path>
            </svg>
            <span className="text-capitalize">guide</span>
          </button>
        </div>
        <div className="mb-3 shadow card">
          <div role="alert" className="fade d-flex align-items-center justify-content-between alert alert-warning show" style={{ fontSize: '0.7rem', textAlign: 'unset' }}>
            <span>
              <b>
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16" width="20" height="20" fill="red">
                  <path d="M8.982 1.566a1.13 1.13 0 0 0-1.96 0L.165 13.233c-.457.778.091 1.767.98 1.767h13.713c.889 0 1.438-.99.98-1.767L8.982 1.566zM8 5c.535 0 .954.462.9.995l-.35 3.507a.552.552 0 0 1-1.1 0L7.1 5.995A.905.905 0 0 1 8 5zm.002 6a1 1 0 1 1 0 2 1 1 0 0 1 0-2z"></path>
                </svg>
                &nbsp;&nbsp;यदि आप किसी भी पेमेंट का बैंक से Complain डालते है तो आपके खाते को Block कर दिया जायेगा ! इसलिए किसी और से अपने Ludo ID में पैसे न डलवाये ! और यदि आप खुद जान भूझकर बैंक से रिफंड लेने के लिए Complain डालते है तो आपकी Ludo ID पूर्णतः बंद कर दी जाएगी !
              </b>
            </span>
          </div>
          <div className="bg-light text-dark card-header">Buy Chips</div>
          <div className="card-body">
            <div className="form-group">
              <div className="d-flex flex-column justify-content-center align-items-center">
                <label htmlFor="amount" className="form-label w-100 text-start">Enter Amount</label>
                <div className="input-group mb-4">
                  <span className="input-group-text bg-light text-dark">₹</span>
                  <input type="number" placeholder="Amount" id="amount" min="1" max="20000" className="form-control" onChange={(e) =>{setAmount(e.target.value)}}  />
                
                </div>


             
                <div id="whichPG" className="d-flex justify-content-evenly align-items-center mb-2 w-100">
                  {/* <div className="form-check form-check-inline">
                    <input type="radio" name="whichPG" id="cashfree" className="form-check-input" value="cashfree" />
                    <label htmlFor="cashfree" className="form-check-label">
                      <h5 className="text-capitalize">UPI</h5>
                    </label>
                  </div> */}
                  {/* <div className="form-check form-check-inline">
                    <input type="radio" name="whichPG" id="phonepe" className="form-check-input" value="phonepe" checked />
                    <label htmlFor="phonepe" className="form-check-label">
                      <h5 className="text-capitalize">UPI-2</h5>
                    </label>
                  </div> */}
                </div>
                <div>
               <button className="btn btn-primary" onClick={()=>onPay()}>Pay</button>
                 
                </div>
              </div>
            </div>
          </div>
        </div>
        <div>
          <p className="text-capitalize text-secondary ">Payments secured by</p>
          <div className="d-flex justify-content-center align-items-center">
            <div className="hstack gap-2">
              <img src="https://ludo-players.s3.ap-south-1.amazonaws.com/cdn/lp/icons/logos/gpay.svg" alt="gpay logo" width="48" />
              <img src="https://ludo-players.s3.ap-south-1.amazonaws.com/cdn/lp/icons/logos/paytm.svg" alt="paytm logo" width="48" />
              <img src="https://ludo-players.s3.ap-south-1.amazonaws.com/cdn/lp/icons/logos/phonepe.svg" alt="phonepe logo" width="48" />
              <img src="https://ludo-players.s3.ap-south-1.amazonaws.com/cdn/lp/icons/logos/upi.svg" alt="upi logo" width="48" />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Recharge;